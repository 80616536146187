/**
 * Created by vaibhav on 31/3/18
 */
import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import IRPageWrapper from "../../../layouts/IRPageWrapper"
import C1 from "./calendarimg/2015-1.jpg"
import C2 from "./calendarimg/2015-2.jpg"
import C3 from "./calendarimg/2015-3.jpg"
import C4 from "./calendarimg/2015-4.jpg"
import IRBG from "../../../components/IRBG"

import CalendarNav from "../../../components/CalendarNav"
import HomeLogo from "../../../components/HomeLogo"

import IRNav from "../../../components/IRNav"

export default class CalendarPage extends Component {
  render() {
    return (
      <IRPageWrapper>
        <IRBG />
        <Helmet>
          <title>Calendar - EA Technique</title>
        </Helmet>
        <section className="hero is-small">
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column">
                  <div className="section">
                    <HomeLogo />
                    <h1 className="title" id="title-line">
                      Corporate Calendar
                    </h1>
                    <IRNav />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <CalendarNav />
          <div className="column is-offset-2" id="calendar-line">
            <div className="columns">
              <div className="column is-1">
                <img src={C1} />
              </div>
              <div className="column">
                <h1 className="subtitle">Interim Dividend</h1>
                <p>
                  Date Announced : 23/11/2015
                  <br />
                  EX-date : 15/12/2015
                  <br />
                  Entitlement date : 17/12/2015
                  <br />
                  Entitlement time : 05:00:00 PM
                  <br />
                  Entitlement subject : Interim Dividend
                  <br />
                  Entitlement description : Interim Dividend of 1.25 sen per
                  Ordinary Share
                  <br />
                  <br />
                  Financial Year End : 31/12/2015
                  <br />
                  Payment date : 31/12/2015
                </p>
              </div>
            </div>
          </div>
          <div className="column is-offset-2">
            <div className="columns" id="calendar-line">
              <div className="column is-1">
                <img src={C2} />
              </div>
              <div className="column">
                <h1 className="subtitle">Final Dividend</h1>
                <p>
                  Date Announced : 04/06/2015
                  <br />
                  EX-date : 26/06/2015
                  <br />
                  <br />
                  Entitlement date : 30/06/2015
                  <br />
                  Entitlement time : 05:00:00 PM
                  <br />
                  Entitlement subject : Final Dividend
                  <br />
                  Entitlement description : Final Tax Exempt (Single-Tier)
                  dividend of 1 sen per RM0.25 ordinary share in respect of the
                  year ended 31 December 2014.
                  <br />
                  <br />
                  Financial Year End : 31/12/2014
                  <br />
                  Payment date : 13/07/2015
                </p>
              </div>
            </div>

            <div className="columns" id="calendar-line">
              <div className="column is-1">
                <img src={C3} />
              </div>
              <div className="column">
                <h1 className="subtitle">
                  21st Annual General Meeting ("AGM") of E.A. Technique (M)
                  Berhad
                </h1>
                <p>
                  Date of Meeting : 21 May 2015
                  <br />
                  Time : 12:00 PM
                  <br />
                  Venue : Impiana Hall 2, Level 2, Impiana KLCC Hotel, 13 Jalan
                  Pinang, 50450
                  <br />
                  Date of General Meeting Record of Depositors : 14 May 2015
                </p>
              </div>
            </div>

            <div className="columns">
              <div className="column is-1">
                <img src={C4} />
              </div>
              <div className="column">
                <h1 className="subtitle">Circular/Notice to Shareholders</h1>
                <p>PROPOSED SHARE BUY-BACK AUTHORITY</p>
              </div>
            </div>
          </div>
        </section>
      </IRPageWrapper>
    )
  }
}

CalendarPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}
